import de from './de'
import en from './en'
import sv from './sv'
import nl from './nl'

const translations = {
  de,
  en,
  sv,
  nl
}

export default translations
